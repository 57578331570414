import React from 'react';
import { useCookie } from 'react-use';
import { useStaticQuery, graphql } from 'gatsby';
import './Checkout.scss';

const Checkout = ({ title, params }) => {
  const [value, updateCookie, deleteCookie] = useCookie("utm_source");
  const queryParams = Object.entries(params).map(([name, value]) => `${name}=${value}`).join('&');
  const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					deploymentType
				}
			}
		}
	`);
	const config = data.site.siteMetadata.deploymentType === 'staging'
		? require('../../config/config.development')
		: require('../../config/config.production');

  return (
    <div className="checkout">
      <div className='form-with-side-container' style={{ paddingTop: 0 }}>
        <div className='width-container'>
          <div className='row center'>
            <div className='form-container col-sm-12 col-md-8'>
              <div className='row center' style={{ paddingBottom: 12 }}>
                <h4>{title}</h4>
                <iframe id='hf-iframe' className="hf-iframe" style={{ maxWidth: 'unset' }} src={`${config.shopIframeUrl}?${queryParams}${value ? `&utm_source=${value}` : ''}`}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
