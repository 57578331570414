import React from 'react';
import SEO from '../components/SEO/SEO';
import Checkout from '../components/Checkout/Checkout';
import getURLParams from '../helpers/getURLParams';

const CheckoutPage = ({ location: { href }}) => {
	const locationArray = !!href ? href.split('/') : [''];
	const queryParams = locationArray[locationArray.length - 1];
	const params = getURLParams(queryParams);
	const ref = params['ref'];

	return (
		<>
			<SEO
				title='Shop'
				description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
			/>
			<Checkout
				title={!!ref ? 'Purchase your video consultation' : 'Purchase travel test'}
				params={params}
			/>
		</>
	)
};

export default CheckoutPage;
